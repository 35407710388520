<template>
    <div>
        <ScrollPanel style="width: 100%; height: 600px">
            <div class="p-grid">
                <DataTable :value="references" :filters="filters" scrollable :paginator="true" dataKey="id"
                           paginatorPosition="bottom" :rows="page.per_page" :lazy="true" :totalRecords="page.total"
                           :loading="loading"  @page="onPage($event)" class="content">
                    <template #header>
                        <Toolbar class="p-col-12 p-mb-1">
                            <template #left>
                                <h5 class="p-mr-2">
                                   Referencias personales
                                </h5>
                            </template>
                            <template #right>
                                <Button label="Nuevo registro" icon="pi pi-plus" class="p-button-success p-mr-2"
                                        @click="newReference()" />
                            </template>
                        </Toolbar>
                    </template>

                    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :rowClass="col.class"></Column>
                    <Column header="Opciones"  headerStyle="width: 200px">
                        <template #body="slotProps">
                            <Button v-tooltip="'Editar'" icon="pi pi-pencil" class="p-button-rounded p-button-success  p-mr-2" @click="edit(slotProps.data)"/>
                            <Button  v-tooltip="'Eliminar'" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteReference($event, slotProps.data)"/>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </ScrollPanel>
        <Dialog v-model:visible="editDialog" :style="{width: '300px'}" header="Datos de referencia" :modal="true"
                class="p-fluid">

            <div class="p-grid">
                <div class="p-col-12 p-md-12">
                    <div class="p-field">
                        <span class="p-float-label">
                            <input-text id="name" type="text" v-model="data.name"
                                        :class="{'p-invalid': submitted && !data.name }" class="p-inputtext-sm"></input-text>
                            <label for="name">Nombre</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.name">El nombre es requerido</small>
                    </div>
                    <div class="p-field">
                        <span class="p-float-label">
                            <input-text id="last_name" type="text" v-model="data.last_name"
                                        :class="{'p-invalid': submitted && !data.last_name }" class="p-inputtext-sm"/>
                            <label for="last_name">Apellido</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.last_name">El apellido es requerido</small>
                    </div>
                    <div class="p-field">
                        <span class="p-float-label">
                            <input-text id="phone" type="text" v-model="data.phone"
                                        :class="{'p-invalid': submitted && !data.phone }" class="p-inputtext-sm"/>
                            <label for="last_name">Teléfono</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.phone">El teléfono es requerido</small>
                    </div>
                </div>
            </div>

            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog = false"/>
                <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveReference"/>
            </template>
        </Dialog>
    </div>

</template>

<script>
    import service from '../../service/references.service'
    import {mapState} from "vuex";

    export default {
        name: "PersonalReferences",
        props: {
            holder: Object,
        },
        data() {
            return {
                service: service('personal/references'),
                columns: [
                    {field: 'name', header: 'Nombre'},
                    {field: 'last_name', header: 'Apellido'},
                    {field: 'phone', header: 'Teléfono'}
                ],
                page: {
                    page: 1,
                    per_page: 5,
                    total: 0,
                    order: "desc"
                },
                references: [],
                data: {},
                filters: {},
                submitted: false,
                loading: false,
                editDialog: false,
                verifyDataDialog: false
            }
        },
        methods: {
            deleteReference(event, data) {
                this.$confirm.require({
                    target: event.currentTarget,
                    message: 'Desea eliminar el registro?',
                    icon: 'pi pi-exclamation-triangle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.loading = true;
                        this.service.delete(data.id).then(() => {
                            this.getData();
                        }).catch(err => {
                            const message = err.response.data;
                            let error = this.$utils.formatError(message);
                            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                        }).finally(() => this.loading = false);
                    },
                    reject: () => {
                    }
                });
            },
            edit(val) {
                this.currentItem = val;
                this.data = val;
                this.$nextTick(() => {
                    this.editDialog = true;
                })

            },
            onPage(event) {
                this.page.page = event.page + 1;
                this.getData()
            },
            newReference() {
                this.submitted = false;
                this.currentItem = null;
                this.data =  {};
                this.editDialog = true;
            },
            getData() {
                this.loading = true;
                this.service.getByHolder(this.holder.id, this.page).then(x => {
                    this.references = x.data;
                    this.page.total = this.references.length;
                }).catch(() => this.references = []).finally(() =>   {
                    this.loading = false
                })
            },
            formatData() {
                this.data.holder_id = this.holder.id;
            },
            saveReference() {
                this.submitted = true;
                if(!this.data.name || !this.data.last_name || !this.data.phone) {
                    return;
                }
                this.formatData();
                this.service.save(this.data, this.currentItem?.id).then(() => {
                    this.getData();
                    this.editDialog = false;
                }).catch(err => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                })
            },
        },
        computed: {
            ...mapState('enroll', ['country']),
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped lang="scss">

    .p-field{
        margin-top: 30px !important;
    }
    .content {
        overflow-wrap: break-word;
    }

    ::v-deep .p-dialog-content{
        height: 100%;
        overflow: inherit!important;

    }


</style>
